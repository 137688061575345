
import { LitElement, html, css } from 'lit';
import { flexStyles } from '../styles/flex-styles';
import { krumStyles } from '../styles/krum-styles';
import navItems from '../assets/data/nav-items.js';

export class KrumWebHeader extends LitElement {
    static get properties() {
        return {
            /**
             * Path to desired logo
             */
            logo: {
                type: String,
            },
            /**
             * Path to desired header background
             */
            background: {
                type: String,
            },
            /**
             * Path to desired banner background
             */
            banner: {
                type: String,
            },
            /**
             * Boolean attribute to stick the whole header at the top.
             */
            sticky: {
                type: Boolean,
                attribute: true,
                reflect: true,
            },
            /**
             * Boolean attribute to disable condensing behavior.
             */
            locked: {
                type: Boolean,
                attribute: true,
                reflect: true,
            },
            /**
             * Total collection of navigation items
             */
            navItems: {
                type: Array,
            },
            /**
             * Current page's collection of navigation items
             */
            currentPage: {
                type: Array,
            },
        };
    }

    /**
     * Component's constructor.  Sets the component's default values.
     */
    constructor() {
        super();
        // this.background = '/images/header-background.png';
        // this.banner = '/images/containers.png';
        this.locked = false;
        this.open = false;
        this.showGoBack = false;
        this.backQueue = [];
        this.navItems = navItems;
        this.currentPage = this.navItems;
        
        // this.throttledFunction = throttle(this.handleScroll, 300).bind(this);
    }

    /**
     * Configures the component's styling
     */
    static get styles() {
        return [
            flexStyles,
            krumStyles,
            css`
                :host {
                    --header-content-max-width: var(--krum-content-max-width);
                    display: block;
                    height: 584px;
                    width: 100%;
                    box-sizing: border-box;
                }

                :host([sticky]) {
                    position: sticky;
                    /* height: 60px; */
                    top: -524px;
                    z-index: 2;
                }
                :host([locked]) {
                    position: sticky;
                    height: 60px;
                    top: 0px;
                    z-index: 2;
                }

                #header {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: bottom;
                    padding: 0 32px;
                    box-sizing: border-box;
                }

                .nav-section {
                    top: 0;
                    position: sticky;
                    z-index: 2;
                }

                .nav-row {
                    height: 60px;
                    max-width: calc(var(--header-content-max-width) - 62px);
                }

                .nav-row ::slotted(*) {
                    margin-left: 16px;
                }

                .nav-mobile-contents {
                        display: none;
                }
                
                @media (max-width: 815px) {
                    .nav-contents {
                        display: none;
                    }
                    .nav-mobile-contents {
                        display: block;
                    }
                }

                .banner-content-section {
                    height: calc(100% - 60px);
                    overflow: hidden;
                }

                .banner-content-container {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: right;
                }
                
                .banner-content {
                    max-width: calc(var(--header-content-max-width) - 62px);
                }

                
                krum-web-button.icn-btn::part(button) {
                    padding: 8px;
                    height: 40px;
                    width: 40px;
                }

                krum-web-button.back-btn::part(button) {
                    color: var(--krum-blue-text-color);
                    font-size: 14px;
                }

                krum-web-button.back-btn img {
                    margin-right: 16px;
                }

                krum-web-button.back-btn:hover span {
                    text-decoration: underline;
                    color: var(--krum-action-color);
                }

                .menu-dropdown {
                    position: fixed;
                    top: 60px;
                    left: 0px;
                    height: calc(100% - 60px);
                    width: 100%;
                    background-color: var(--krum-white-text-color);
                    z-index: 1000;
                }

                .menu-row {
                    /* the 8px here accounts for the icon padding */
                    padding: 16px 8px 16px 16px;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--krum-black-text-color);
                    cursor: pointer;
                    height: 56px;
                    box-sizing: border-box;
                    text-decoration: none;
                    border-bottom: 1px solid var(--krum-muted-black-text-color);
                }
                
                .disabled-menu-row {
                    /* the 8px here accounts for the icon padding */
                    padding: 16px 8px 16px 16px;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--krum-color-medium-dark-gray);
                    cursor: default;
                    height: 56px;
                    box-sizing: border-box;
                    text-decoration: none;
                    border-bottom: 1px solid var(--krum-muted-black-text-color);
                }

                .menu-row:last-child {
                    border-bottom: none;
                }
                
                .menu-row > a, .menu-row > a:visited {
                    text-decoration: none;
                    color: var(--krum-black-text-color);
                }

                .menu-row:hover, .menu-row:hover > a {
                    color: var(--krum-action-color);
                    text-decoration: underline;
                }
            `,
        ];
    }
    /**
     * Template literal rendering for the component's HTML.
     */
    render() {

        let three_icons = html`
            <div class="horizontal center">
                <!-- <krum-web-button class="icn-btn" type="text">
                    <img src="/images/icons/icn-search.svg" />
                </krum-web-button>
                <krum-web-button class="icn-btn" type="text">
                    <img src="/images/icons/icn-profile.svg" />
                </krum-web-button> -->
                <krum-web-button class="icn-btn" type="text" @click="${this._handleMenuClick}">
                    <img src="/images/icons/icn-close.svg" />
                </krum-web-button>
            </div>
        `;
        let menu_button = html`
            <krum-web-button class="icn-btn" type="text" @click="${this._handleMenuClick}">
                <img src="/images/icons/hamburger-icon.svg" />
            </krum-web-button>
        `;

        // We will show the hamburger first then the icons once the drawer has opened
        let mobileDrawer = this.open ? three_icons : menu_button;

        // Build the overlay menu based on the collection from nav-options.js
        let menuDropdown = this.open
            ? html`
                <div class="vertical menu-dropdown">
                    ${
                        this.backQueue.length > 0
                        ? html`
                            <krum-web-button class="back-btn" type="text" @click="${this._handleGoBackSelection}">
                                <div class="horizontal center">
                                    <img src="/images/icons/arrow-left.svg" />
                                    <span>Back</span>
                                </div>
                            </krum-web-button>
                        ` : ""
                    }
                    ${
                        this.currentPage.map((menuItem) => html`
                            ${
                                menuItem.href
                                ? (menuItem.disabled ? html`
                                        <div class="horizontal center between-justify disabled-menu-row">
                                            <span class="menu-text">${"\uD83D\uDEA7"} ${menuItem.title}</span>
                                        </div>
                                    `
                                    : html`
                                        <a href="${menuItem.href}" target="${menuItem.target ? menuItem.target : ''}" class="horizontal center between-justify menu-row"
                                            @click="${() => this._handleMenuSelectionClick(menuItem, this.currentPage)}">
                                            <span class="menu-text">${menuItem.title}</span>
                                        </a>
                                    `
                                ) : html`
                                    <div class="horizontal center between-justify menu-row"
                                        @click="${() => this._handleMenuSelectionClick(menuItem, this.currentPage)}">
                                        <span class="menu-text">${menuItem.title}</span>
                                        <krum-web-button class="icn-btn" type="text">
                                            <img src="/images/icons/arrow-right.svg" />
                                        </krum-web-button>
                                    </div>
                                `
                            }
                        `)
                    }
                </div>
            ` : null;

        return html`
            <header id="header" class="full-height full-width vertical center"
             style="background-image: url(${this.background});">
                <section id="nav" class="center full-width nav-section vertical center">
                    <div class="horizontal center full-width nav-row">
                        <a href="/"><img src="${this.logo}" /></a>
                        <div class="flex"></div>
                        <slot class="nav-contents" name="navigation"></slot>
                        <div class="nav-mobile-contents">${mobileDrawer}</div>
                    </div>
                </section>
                ${menuDropdown}
                <section class="banner-content-section full-width">
                    <div class="banner-content-container full-height full-width vertical center"
                     style="background-image: url(${this.banner});">
                        <div class="banner-content full-width">
                            <slot name="banner"></slot>
                        </div>
                    </div>
                </section>
            </header>
        `;
    }

    firstUpdated() {
        // Instantiation
        this.handleScroll();
    }

    connectedCallback() {
        super.connectedCallback();
        // If the component is specified as condensed by default, do not establish scroll listener
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        // If the component is specified as condensed by default, the scroll listener was not established
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll(e) {
        if(this.locked) {
            this.setAttribute('sticky', true);
            return;
        }

        // determine whether or not we should stick
        if(!this.sticky && Math.round(window.scrollY) > 524) {
            this.setAttribute('sticky', true);
        } else if(this.sticky && window.scrollY <= 0) {
            this.removeAttribute('sticky');
            // window.scroll(0, 524);
        }

        // This enables us to scroll our text behind a transparent div
        const tempBanner = this.shadowRoot.querySelector('.banner-content-container');
        if(tempBanner) {
            tempBanner.style.clipPath = `inset(${window.scrollY}px 0 0 0)`;
        }
    }

    /**
     * Observer for attributes/properties defined above.  This will dispatch events for each
     * property's individual changes.  If the `value` property is changed, this will perform
     * automatic validation if configured to do so by the `autoValidate` property.
     *
     * @param {String} name - The name of the changed property
     * @param {*} oldval - The value of the property prior to the recorded change
     * @param {*} newval - The new value of the given property
     *
     * @returns {void}
     */
    attributeChangedCallback(name, oldval, newval) {
        super.attributeChangedCallback(name, oldval, newval);
        // disallow header with banner while locked
        if(this.locked && name === 'sticky' && newval != 'true') {
            this.setAttribute('sticky', true);
        }

        if(name === 'locked' && newval == 'true') {
            this.setAttribute('sticky', true);
        } else if(name === 'locked') {
            this.handleScroll();
        }
    }
    
    _handleMenuClick() {
        const oldVal = this.open;
        this.open = !this.open;
        if (!this.open) {
            this._handleClose();
        }
        this.requestUpdate('open', oldVal);
    }

    _handleMenuSelectionClick(currentMenu, currentFullMenu) {
        if(currentMenu.href) {
            this._handleClose();
        } else {
            this.showGoBack = true;
            this.requestUpdate('showGoBack', !this.showGoBack);
            this.currentPage = currentMenu.pages || currentMenu.subpages;
            this.backQueue.push(currentFullMenu);
        }
    }

    _handleClose() {
        this.showGoBack = false;
        this.requestUpdate('showGoBack', !this.showGoBack);
        this.currentPage = this.navItems;
        this.backQueue = [];
        this.open = false;
    }

    _handleGoBackSelection() {
        this.requestUpdate('showGoBack', !this.showGoBack);
        this.currentPage = this.backQueue[this.backQueue.length - 1];
        this.backQueue.splice([this.backQueue.length - 1], 1);

        if (this.backQueue.length < 1) {
            this.showGoBack = false;
        }
    }

}

customElements.define('krum-web-header', KrumWebHeader);