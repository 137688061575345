const navItems = [
    {
        "title": "Home",
        "href": "/",
    },
    // {
    //     "title":"Solutions",
    //     "pages": [
    //         {
    //             "title": "Why Krum?",
    //             "href": "#why-krum",
    //         },
    //         {
    //             "title": "Services",
    //             "subpages": [
    //                 {
    //                     "title": "Application Development",
    //                     "href": "#Application-Development",
    //                 },
    //                 {
    //                     "title": "Acceleration",
    //                     "href": "#Acceleration",
    //                 },
    //                 {
    //                     "title": "Managed Ops",
    //                     "href": "#Managed-Ops",
    //                 },
    //                 {
    //                     "title": "Excellence & Enablement",
    //                     "href": "#Excellence-Enablement",
    //                 },
    //             ],
    //         },
    //         {
    //             "title": "Stacks",
    //             "subpages": [
    //                 {
    //                     "title": "Kubernetes",
    //                     "href": "#Kubernetes",
    //                 },
    //                 {
    //                     "title": "ITOps",
    //                     "href": "#ITOps",
    //                 },
    //                 {
    //                     "title": "Mangaged Ops",
    //                     "href": "#Managed-Ops-Stack",
    //                 },
    //                 {
    //                     "title": "Dev Ops",
    //                     "href": "#Dev-Ops",
    //                 },
    //                 {
    //                     "title": "Data",
    //                     "href": "#Data",
    //                 },
    //                 {
    //                     "title": "The Ultimate",
    //                     "href": "#The-Ultimate",
    //                 },
    //             ],
    //         },
    //     ],
    // },
//     {
//         "title":"Partners",
//         "pages": [
//             {
//                 "title": "SUSE & Rancher",
//                 "href": "/partner/suse",
//             },
//             {
//                 "title": "Elastic",
//                 "href": "/partner/elastic",
//                 "disabled": true,
//             },
//             {
//                 "title": "Kubecost",
//                 "href": "/partner/kubecost",
//                 "disabled": true,
//             },
//             {
//                 "title": "Stripe",
//                 "href": "/partner/stripe",
//                 "disabled": true,
//             },
//             {
//                 "title": "Velocity",
//                 "href": "/partner/velocity",
//                 "disabled": true,
//             },
//         ],
//     },
    // {
    //     "title":"Partner Solutions",
    //     "pages": [
    //         {
    //             "title": "Cloud + DevOps + IOT",
    //             "subpages": [
    //                 {
    //                     "title": "SUSE Rancher",
    //                     "href": "/partners/suse",
    //                 },
    //             ],
    //         },
    //         {
    //             "title": "Data & APM",
    //             "subpages": [
    //                 {
    //                     "title": "Elasticsearch",
    //                     "href": "/partners/elasticsearch",
    //                 },
    //             ],
    //         },
    //         {
    //             "title": "Infrastructure",
    //             "subpages": [
    //                 {
    //                     "title": "Choose your provider",
    //                     "href": "/partners/infrastructure",
    //                 },
    //                 {
    //                     "title": "Bare Metal",
    //                     "href": "/partners/suse",
    //                 },
    //             ],
    //         },
    //         {
    //             "title": "Cost Monitoring",
    //             "subpages": [
    //                 {
    //                     "title": "Kubecost",
    //                     "href": "/partners/kubecost",
    //                 },
    //             ],
    //         },
    //         {
    //             "title": "Money Management",
    //             "subpages": [
    //                 {
    //                     "title": "Stripe",
    //                     "href": "/partners/stripe",
    //                 },
    //             ],
    //         },
    //         {
    //             "title": "Security & Compliance",
    //             "subpages": [
    //                 {
    //                     "title": "Velocity",
    //                     "href": "/partners/velocity",
    //                 },
    //                 // {
    //                 //     "title": "NeuVector",
    //                 //     "href": "/partners/neuvector",
    //                 // },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     "title": "About",
    //     "href": "/about",
    // },
    {
        "title": "Careers",
        "href": "/careers",
    },
    {
        "title": "Blog",
        "href": "https://blog.krum.io/",
        "target": "_blank",
    },
    {
        "title": "Contact Us",
        "href": "/contact",
    },
    // {
    //     "title":"Customers",
    //     "pages": [
    //         {
    //             "title": "SUSE",
    //             "subpages": [
    //                 {
    //                     "title": "Application Development",
    //                     "href": "#Application-Development",
    //                 },
    //                 {
    //                     "title": "Acceleration",
    //                     "href": "#Acceleration",
    //                 },
    //                 {
    //                     "title": "Managed Ops",
    //                     "href": "#Managed-Ops",
    //                 },
    //                 {
    //                     "title": "Execellence/Enbablement",
    //                     "href": "#Execellence-Enbablement",
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     "title":"About",
    //     "pages": [
    //         {
    //             "title": "SUSE",
    //             "subpages": [
    //                 {
    //                     "title": "Application Development",
    //                     "href": "#Application-Development",
    //                 },
    //                 {
    //                     "title": "Acceleration",
    //                     "href": "#Acceleration",
    //                 },
    //                 {
    //                     "title": "Managed Ops",
    //                     "href": "#Managed-Ops",
    //                 },
    //                 {
    //                     "title": "Execellence/Enbablement",
    //                     "href": "#Execellence-Enbablement",
    //                 },
    //             ],
    //         },
    //     ],
    // },
];

export default navItems;
